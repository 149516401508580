import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import Splide from '@splidejs/splide';

const marqueeSliders = document.getElementsByClassName('splide');

function isInViewport(element) {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0
    && rect.left >= 0
    && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
    && rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

function prefersReducedMotion() {
  return window.matchMedia('(prefers-reduced-motion: reduce)').matches;
}

function checkAndTogglePlay(sliderInstance, element) {
  if (isInViewport(element)) {
    if (prefersReducedMotion()) {
      sliderInstance.Components.AutoScroll.pause(); // Ensure this method exists in your Splide version
    } else {
      sliderInstance.Components.AutoScroll.play(); // Ensure this method exists in your Splide version
    }
  } else {
    sliderInstance.Components.AutoScroll.pause();
  }
}

if (marqueeSliders.length > 0) {
  for (let i = 0; i < marqueeSliders.length; i++) {
    const speed = 1;
    const direction = i % 2 === 0 ? speed : -speed;
    const gap = '7vw';

    const sliderInstance = new Splide(marqueeSliders[i], {
      type: 'loop',
      drag: false,
      omitEnd: true,
      perPage: 9,
      gap,
      lazyLoad: 'nearby',
      autoScroll: {
        speed: direction,
        pauseOnHover: false,
        pauseOnFocus: false,
      },
    }).mount({ AutoScroll });

    // Check and toggle on load
    checkAndTogglePlay(sliderInstance, marqueeSliders[i]);

    // Check and toggle on scroll
    window.addEventListener('scroll', () => {
      checkAndTogglePlay(sliderInstance, marqueeSliders[i]);
    });

    // Check and toggle on resize
    window.addEventListener('resize', () => {
      checkAndTogglePlay(sliderInstance, marqueeSliders[i]);
    });
  }
}
